import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today is our monthly free CrossFit class so bring a friend!  Classes
at 8:00 & 9:00am at The Ville and 10:00 & 11:00am at Louisville
East!`}{`*`}</strong></p>
    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids today.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar with Dawn from Sports Nutrition 2 Go in Cincinnati.
Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
We will need 20 people in order to have this seminar so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend. \\$35/person
for the seminar.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      